import kaztechLogo from "../assets/Collaborators/kaztech.jpg";
// import akseuLogo from "../assets/Collaborators/akseu.jpg";
import pinnacleLogo from "../assets/Collaborators/pinnacle.jpg";
import dbspaceLogo from "../assets/Collaborators/dbspace.jpg";
import kolokLogo from "../assets/Collaborators/kolok.jpg";
import omlLogo from "../assets/Collaborators/oml.jpg";

const collaboratorLogos = [
  {
    id: 0,
    logo: kaztechLogo,
  },
  // {
  //   id: 1,
  //   logo: akseuLogo,
  // },
  {
    id: 1,
    logo: pinnacleLogo,
  },
  {
    id: 2,
    logo: dbspaceLogo,
  },
  {
    id: 3,
    logo: kolokLogo,
  },
  {
    id: 4,
    logo: omlLogo,
  },
];

export default collaboratorLogos;
