import React from "react";
import { Div, Col, Anchor, Text, Button, Icon } from "atomize";

// Component for Social Media Button
const SocialMediaButton = ({ icon, color, link }) => (
  <Anchor href={link} target="_blank" m={{ r: "1rem" }}>
    <Button
      bg={color}
      textColor="#FFFFFF"
      suffix={<Icon name={icon} size="16px" color="#OOOOOO" />}
      shadow="3"
      hoverShadow="4"
    >
      {/* {icon} */}
    </Button>
  </Anchor>
);

const TeamCard = (props) => {
  return (
    <Col size={{ xs: 12, lg: 4 }}>
      <div
        style={{
          borderRadius: 5,
          backgroundColor: "white",
          margin: "1rem",
        }}
      >
        <Div
          shadow="0 10px 24px -10px black"
          rounded="12px"
          h="auto"
          d="flex"
          flexDir="column"
          align="center"
          justify="center"
          p={{ t: "40px", b: "20px" }}
        >
          <Div
            bg="#063159"
            rounded="circle"
            h="10rem"
            w="10rem"
            d="flex"
            flexDir="column"
            align="center"
            justify="center"
            bgImg={props.img}
            bgSize="cover"
            bgPos="center"
          ></Div>
          <Text
            tag="h3"
            textSize="subheader"
            textColor="dark"
            textWeight="400"
            p="10px"
            textAlign={{ xs: "center", lg: "center" }}
          >
            {props.name}
          </Text>

          <Text
            tag="h1"
            textColor="black"
            textSize="paragraph"
            textAlign={{ xs: "center", lg: "center" }}
            textWeight="300"
            p={{ l: "20px", r: "20px" }}
          >
            {props.title}
          </Text>
          <div
            style={{
              borderBottom: "solid 1px black",
              borderTop: "solid 1px black",
              padding: "10px",
              margin: "10px",
              fontStyle: "italic",
              textAlign: "center",
            }}
          >
            {`"${props.quote}"`}
          </div>
          <Div d="flex" justify="center" m={{ t: "20px" }}>
            <SocialMediaButton
              icon="Linkedin"
              color="info800"
              link="https://www.linkedin.com/"
            />
            <SocialMediaButton
              icon="Twitter"
              color="info800"
              link="https://twitter.com"
            />
            <SocialMediaButton
              icon="Mail"
              color="info800"
              link=" https://plus.google.com"
            />
          </Div>
        </Div>
      </div>
    </Col>
  );
};

export default TeamCard;
