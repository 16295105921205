import React from "react";
import { Div, Col, Row, Container, Text } from "atomize";
import "./containers.css";

const About = () => {
  return (
    <div>
      {/* Main Container */}
      <Container
        bg="#FFFFFF"
        h="50%"
        d="flex"
        flexDir="column"
        justify="center"
        align="center"
        p="4rem"
      >
        {/* Content Container */}
        <Div w="100%" d="flex" flexDir="column" justify="center" align="center">
          {/* Title */}
          <Text
            tag="h1"
            textSize="display2"
            m={{ b: "2rem" }}
            textColor="dark"
            textWeight="600"
            textAlign={{ xs: "center", lg: "center" }}
          >
            AKSEu Business Solutions
          </Text>
          {/* Main Text */}
          <Text
            tag="h1"
            textColor="black"
            textSize="subheader"
            textAlign={{ xs: "justify", lg: "justify" }}
            textWeight="300"
            p={{ l: "20px", r: "20px" }}
          >
            Welcome to AKSEu Business Solutions, where innovation meets excellence in the heart of Namibia. Founded in 2022 by a passionate team of IT graduates, we embarked on a journey to redefine the landscape of software engineering and research. Our commitment to knowledge, growth, and leaving a lasting legacy has been the driving force behind our every endeavor.
          </Text>
          {/* Three Columns */}
          <Row w="100%" d="flex" justify="center">
            {/* Column 1 */}
            <Col size={{ xs: 12, lg: 4 }}>
              <Text
                tag="h3"
                textSize="display1"
                textColor="dark"
                textWeight="400"
                textAlign={{ xs: "center", lg: "center" }}
              >
                Our vision
              </Text>
              <Text
                tag="h1"
                textColor="black"
                textSize="subheader"
                textAlign={{ xs: "center", lg: "center" }}
                textWeight="300"
                p={{ l: "20px", r: "20px" }}
              >
                Become a technological driving force in Namibia
              </Text>
            </Col>
            {/* Column 2 */}
            <Col size={{ xs: 12, lg: 4 }}>
              <Text
                tag="h3"
                textSize="display1"
                textColor="dark"
                textWeight="400"
                textAlign={{ xs: "center", lg: "center" }}
              >
                Our values
              </Text>
              <Text
                tag="h1"
                textColor="black"
                textSize="subheader"
                textAlign={{ xs: "justify", lg: "justify" }}
                textWeight="300"
                p={{ l: "20px", r: "20px" }}
              >
                <b>Innovation:</b> We believe in the power of innovation to transform industries and drive progress. Our commitment to staying at the forefront of technological advancements enables us to provide solutions that stand the test of time.
                <br />
                <b>Collaboration:</b> We value collaboration as the cornerstone of success. By fostering an environment where diverse perspectives thrive, we harness collective intelligence to solve complex challenges and deliver unparalleled results.
                <br />
                <b>Integrity:</b> Trust is the bedrock of our relationships, both within our team and with our clients. We uphold the highest standards of integrity, ensuring transparency, honesty, and ethical conduct in everything we do.
                <br />
                <b>Continuous Learning:</b> In the dynamic world of technology, continuous learning is not just a choice, but a necessity. We are committed to cultivating a culture of perpetual learning, where each team member is empowered to expand their knowledge and skills.
              </Text>
            </Col>
            {/* Column 3 */}
            <Col size={{ xs: 12, lg: 4 }}>
              <Text
                tag="h3"
                textSize="display1"
                textColor="dark"
                textWeight="400"
                textAlign={{ xs: "center", lg: "center" }}
              >
                Our mission
              </Text>
              <Text
                tag="h1"
                textColor="black"
                textSize="subheader"
                textAlign={{ xs: "justify", lg: "justify" }}
                textWeight="300"
                p={{ l: "20px", r: "20px" }}
              >
                At AKSEu Business Solutions, our mission is to pioneer cutting-edge solutions that transcend boundaries, making a positive impact on businesses and communities alike. We are dedicated to pushing the boundaries of what's possible in software engineering and research, driven by a passion to contribute meaningfully to the world. Through our relentless pursuit of excellence, we strive to empower businesses, elevate technological standards, and foster a culture of continuous innovation.
              </Text>
            </Col>
          </Row>
        </Div>
      </Container>
    </div>
  );
};

export default About;
