import "../App.css";
import { ThemeProvider, Row, Col } from "atomize";
import {
  Contact,
  Testimonial,
} from "../components/Components";

const theme = {
  colors: {
    green: "##6FCAA8",
    darkGreen: "#36AB80",
    black: "#0A1F44",
    dark: "#0A1F44",
    white: "#FFFFF",
    gray: "#B0B7C3",
    darkGray: "#8A94A6",
    blue: "#026DD6",
    darkBlue: "#01408F",
  },
};

function App() {
  return (
    <ThemeProvider theme={theme}>
        <Row>
          <Col size={{ xs: 12, lg: 6 }}>
            <Testimonial />
          </Col>
          <Col size={{ xs: 12, lg: 6 }}>
            <Contact />
          </Col>
        </Row>
    </ThemeProvider>
  );
}

export default App;
