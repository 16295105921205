import React, { useState } from "react";
import { Container, Input, Button, Div, Text } from "atomize";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const Login = () => {

    const navigate = useNavigate();

    const [loginData, setLoginData] = useState({
        username: "",
        password: "",
    });

    const [submitting, setSubmitting] = useState(false);
    const [loginErrorMessage, setLoginErrorMessage] = useState(null);

    const handleLoginChange = (e) => {
        const { name, value } = e.target;
        setLoginData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleLoginSubmit = async (e) => {
        e.preventDefault();

        try {
            setSubmitting(true);

            // Make your login API call here
            const response = await axios.post("http://localhost:5000/api/login", loginData);

            // Handle login success or failure
            console.log(response.data);
            setLoginErrorMessage(null);

            // Optionally, redirect the user on successful login
            navigate("/home");
        } catch (error) {
            // Handle error and display an error message
            setLoginErrorMessage("Invalid username or password.");
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Container p={{ x: { xs: "1rem", md: "2rem" }, y: "2rem" }} m="auto" w={{ xs: "90%", md: "60%" }}>
            <Div textAlign="center"
                p={{ x: { xs: "1rem", md: "2rem" }, y: "2rem" }}
                m="auto"
                w={{ xs: "90%", md: "50%" }}>
                <Text textSize="heading" textColor="dark" m={{ b: "1rem" }}>
                    Login
                </Text>
                <form onSubmit={handleLoginSubmit}>
                    <Input
                        placeholder="Email"
                        name="client_email"
                        onChange={handleLoginChange}
                        value={loginData.client_email}
                        m={{ b: "1rem" }}
                        required
                    />
                    <Input
                        placeholder="Password"
                        name="password"
                        type="password"
                        onChange={handleLoginChange}
                        value={loginData.password}
                        m={{ b: "1rem" }}
                        required
                    />
                    <Button type="submit" bg="rgba(6, 49, 89)" textColor="white" disabled={submitting}>
                        {submitting ? "Logging in..." : "Login"}
                    </Button>
                </form>
                <Link to="/sign up" style={{ display: "block", marginTop: "1rem" }}>
                    Don't have an account yet? Register
                </Link>
                {loginErrorMessage && <p style={{ color: "red", marginTop: "1rem" }}>{loginErrorMessage}</p>}
            </Div>
        </Container>
    );
};

export default Login;
