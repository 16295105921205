import React, { useState, useEffect } from "react";
import { Div, Text, Image, Button } from "atomize";
import gamerAdvert1 from "../../assets/Adverts/gamer.jpg";
import gamerAdvert2 from "../../assets/Adverts/gamer.jpg";
import gamerAdvert3 from "../../assets/Adverts/gamer.jpg";

const AdvertSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const totalSlides = 3;

  useEffect(() => {
    // Set interval to automatically switch slides every 6 seconds
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % totalSlides);
    }, 6000);

    // Cleanup interval to avoid memory leaks
    return () => clearInterval(interval);
  }, [currentSlide, totalSlides]);

  const sliderData = [
    {
      id: 1,
      title: "Game Advert 1",
      image: gamerAdvert1,
    },
    {
      id: 2,
      title: "Game Advert 2",
      image: gamerAdvert2,
    },
    {
      id: 3,
      title: "Game Advert 3",
      image: gamerAdvert3,
    },
  ];

  const [hideAd, setHideAd] = useState(false);

  const handleHideAd = () => {
    // Set hideAd state to true when the button is clicked
    setHideAd(true);
  };

  return (
    <Div style={{ display: hideAd ? "none" : "block" }}>
      <Div
        overflow="hidden"
        h="55vh"
        d="flex"
        p="40px"
        flexDir="column"
        align="center"
        justify="center"
      >
        <Image
          w="80%"
          rounded="md"
          src={sliderData[currentSlide].image}
          alt={sliderData[currentSlide].title}
          className="slider-image"
          shadow="0 10px 24px -10px black"
        />
      </Div>
      <Text
        tag="h6"
        textSize="large"
        textAlign="center"
        textColor="rgba(6, 49, 89, 0.8)"
        textWeight="500"
        p="10px"
      >
        <a href="your_advertise_link_here">
          {/* Icon for mouse pointer */}
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mouse-pointer">
            <path d="M3 3l7.07 16.97 2.51-7.39 7.39-2.51L3 3z"></path>
            <path d="M13 13l6 6"></path>
          </svg>
          Click to advertise with us!
        </a>
        {/* Button to hide the ad */}
        <Button
        m="auto"
          onClick={handleHideAd}
          p="10px"
          bg="#FFFFFF"
          textColor="rgba(6, 49, 89, 0.8)"
        >
          Hide ad
        </Button>
      </Text>
    </Div>
  );
};

export default AdvertSlider;
