import theronImage from "../assets/Team/theron.jpg";
import parcidioImage from "../assets/Team/parcidio.jpg";
import jessicaImage from "../assets/Team/jessica.jpg";
import augustoImage from "../assets/Team/augusto.jpg";

const teamMembers = [
  {
    id: 0,
    name: "Theron Kasita",
    title: "Chief Executive Officer",
    quote: "We are the hands of progress...",
    img: theronImage,
    linkedin: "theron-linkedin",
    facebook: "theron-facebook",
    instagram: "theron-instagram",
    twitter: "theron-twitter",
    google: "theron-google",
  },
  {
    id: 1,
    name: "Parcidio Andre",
    title: "Chief Technology Officer",
    quote: "We are the hands of progress...",
    img: parcidioImage,
    linkedin: "parcidio-linkedin",
    facebook: "parcidio-facebook",
    instagram: "parcidio-instagram",
    twitter: "parcidio-twitter",
    google: "parcidio-google",
  },
  {
    id: 2,
    name: "Manuela Dos Santos",
    title: "Marketing Manager",
    quote: "We are the hands of progress...",
    img: jessicaImage,
    linkedin: "manuela-linkedin",
    facebook: "manuela-facebook",
    instagram: "manuela-instagram",
    twitter: "manuela-twitter",
    google: "manuela-google",
  },
  {
    id: 3,
    name: "Augusto Domingos",
    title: "Senior Software Engineer",
    quote: "We are the hands of progress...",
    img: augustoImage,
    linkedin: "augusto-linkedin",
    facebook: "augusto-facebook",
    instagram: "augusto-instagram",
    twitter: "augusto-twitter",
    google: "augusto-google",
  },
];

export default teamMembers;
