import React from "react";
import { Div, Col, Anchor, Text, Button, Icon } from "atomize";

const OfferCard = (props) => {
  return (
    <Col size={{ xs: 12, lg: 3 }} d="flex" justify="center">
      <Div
        shadow="0 10px 24px -10px rgba(0, 0, 0, 0.08)"
        rounded="12px"
        bg="rgba(6, 49, 89, 0.8)" // Add opacity to the background color
        p="1rem"
        m="1rem"
        w="100%"
        textColor="#FFFFFF"
      >
        <Text
          tag="h2"
          textSize="subheader"
          textWeight="700"
          textAlign={{ xs: "center", lg: "center" }}
        >
          {props.title}
        </Text>
        <Div
          borderBottom="1px solid"
          borderTop="1px solid"
          p="1rem"
          fontStyle="italic"
          textAlign="center"
          h="10rem"
          w="100%"
        >
          {props.description}
        </Div>
        <Anchor href="https://www.linkedin.com/in/parcidio-andre" target="_blank">
          <Button
            h="2.5rem"
            p={{ x: "1rem" }}
            textSize="body"
            textColor="#063159"
            hoverTextColor="info900"
            bg="#FFFFFF"
            hoverBg="info200"
            border="1px solid"
            borderColor="info700"
            hoverBorderColor="info900"
            m={{ r: "0.5rem" }}
          >
            Read more
            <Icon
              name="LongRight"
              size="16px"
              color="#063159"
              m={{ l: "1rem" }}
            />
          </Button>
        </Anchor>
      </Div>
    </Col>
  );
};

export default OfferCard;
