// Import statements
import "../App.css";
import React, { useState, useEffect } from "react";
import { Container, Input, Button, Div, Text } from "atomize";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const Register = () => {
  const navigate = useNavigate();

  const [registerData, setRegisterData] = useState({
    client_firstname: "",
    client_lastname: "",
    client_email: "",
    client_password: "",
  });

  const [registerErrorMessage, setRegisterErrorMessage] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRegisterData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send a POST request to the server to register the client
      const response = await axios.post(
        "http://localhost:5000/api/clients/register",
        registerData
      );

      // Log the server response and reset error state
      console.log(response.data);
      setRegisterErrorMessage(null);
      setIsSuccess(true);
    } catch (error) {
      // Handle different types of errors during registration
      if (error.response) {
        console.error("Server responded with an error:", error.response.data);
        setRegisterErrorMessage(
          "Failed to register. Please check your data and try again."
        );
      } else if (error.request) {
        console.error("No response received from the server:", error.request);
        setRegisterErrorMessage(
          "Failed to register. No response received from the server."
        );
      } else {
        console.error("Error setting up the request:", error.message);
        setRegisterErrorMessage("Failed to register. Please try again.");
      }
      setIsSuccess(false);
    }
  };

  // Effect to navigate to the login page after successful registration
  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        navigate("/login");
      }, 2000); // Redirect after 2 seconds
    }
  }, [isSuccess, navigate]);


  const RegisterModal = () => {
    return (
      <Container
        p={{ x: { xs: "1rem", md: "2rem" }, y: "2rem" }}
        m="auto"
        w={{ xs: "90%", md: "70%" }}
      >
        <Div textAlign="center">
          <Text textSize="heading" textColor="dark" m={{ b: "1rem" }}>
            Register
          </Text>
          <form onSubmit={handleRegisterSubmit}>
            {/* Input fields for user registration */}
            <Input
              placeholder="First Name"
              name="client_firstname"
              onChange={handleChange}
              value={registerData.client_firstname}
              m={{ b: "1rem" }}
              required
            />
            <Input
              placeholder="Last Name"
              name="client_lastname"
              onChange={handleChange}
              value={registerData.client_lastname}
              m={{ b: "1rem" }}
              required
            />
            <Input
              placeholder="Email"
              name="client_email"
              type="email"
              onChange={handleChange}
              value={registerData.client_email}
              m={{ b: "1rem" }}
              required
            />
            <Input
              placeholder="Password"
              name="client_password"
              type="password"
              onChange={handleChange}
              value={registerData.client_password}
              m={{ b: "1rem" }}
              required
            />
            {/* Button to submit the registration form */}
            <Button type="submit" bg="rgba(6, 49, 89)" textColor="white">
              Register
            </Button>
            {/* Link to the login page */}
            <Link to={`/login`}> Already have an account? Login</Link>
          </form>
          {/* Display registration error message if any */}
          {registerErrorMessage && (
            <p style={{ color: "red" }}>{registerErrorMessage}</p>
          )}
        </Div>
      </Container>
    );
  };

  return (
    <Container
      p={{ x: { xs: "1rem", md: "2rem" }, y: "2rem" }}
      m="auto"
      w={{ xs: "90%", md: "50%" }}
    >
      <RegisterModal />
    </Container>
  );
};

export default Register;
