import React from "react";
import { Div, Row, Text } from "atomize";
import "./containers.css";
import { TeamCard } from "../Components"; // Import TeamCard component
import TeamData from "../../data/TeamMembers";

const Team = () => {
  return (
    <Div bg="transparent" h="50%" d="flex" flexDir="column" justify="center" align="center" p="4rem">
      <Div w={{ xs: "90%", md: "80%", lg: "60%" }} d="flex" flexDir="column" justify="center" align="center">
        <Text
          tag="h3"
          textSize={{ xs: "display1", md: "display2" }}
          m={{ b: "2rem" }}
          textColor="dark"
          textWeight="600"
          textAlign={{ xs: "center", lg: "center" }}
        >
          Executive Members
        </Text>
        <Row w="100%" d="flex" flexWrap="wrap">
          {TeamData.map((teamMember) => (
            <TeamCard
              key={teamMember.id}
              img={teamMember.img}
              name={teamMember.name}
              title={teamMember.title}
              quote={teamMember.quote}
              linkedin={teamMember.linkedin}
              facebook={teamMember.facebook}
              instagram={teamMember.instagram}
              twitter={teamMember.twitter}
              google={teamMember.google}
            />
          ))}
        </Row>
      </Div>
    </Div>
  );
};

export default Team;
