import React, { useState } from "react";
import { Div, Col, Image, Tag } from "atomize";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import logo from "../../assets/logo.png";
import "./containers.css";

const Nav = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const menuItems = ["About", "Team", "Services", "Career", "Media", "Contact", "Login", "Sign Up"];

  const DivLine = () => {
    return (<Div
      style={{
        width: "24px",
        height: "2px",
        backgroundColor: "rgba(6, 49, 89, 0.8)",
        margin: "6px 0",
        borderRadius: "4px",
      }} ></Div>
    )
  }

  return (
    <div
      style={{
        position: "sticky",
        top: "0",
        zIndex: "1000",
        background: "#FFFFFF",
        boxShadow: "0 10px 24px -10px rgba(0, 0, 0, 0.08)",
      }}
    >
      <Div
        justify="space-between"
        d="flex"
        h={{ xs: "auto", lg: "4rem" }}
        align="center"
        p={{ xs: "1rem", lg: "0" }}
      >
        <Col size={{ xs: 4, lg: 2 }}>
          <Div>
            <Link to="/"><Image w={{ xs: "8rem", md: "8rem" }} src={logo} /></Link>
          </Div>
        </Col>
        <Col size={{ xs: 8, lg: 6 }}>
          <Div d={{ xs: "none", lg: "flex" }} justify="end" align="center">
            {menuItems.map((menuItem) => (
              <Link
                key={menuItem}
                to={`/${menuItem.toLowerCase()}`} // Use to prop for navigation
                onClick={closeMenu}
              >
                <Tag
                  p={{ xs: "0.5rem", lg: "1rem" }}
                  hoverBg="rgba(6, 49, 89, 0.8)"
                  hoverTextColor="#FFFFFF"
                  cursor="pointer" bg="#FFFFFF"
                >
                  {menuItem}
                </Tag>
              </Link>
            ))}
          </Div>
        </Col>
        {/* Mobile menu button (hamburger icon) */}
        <Div
          d={{ xs: "inline", lg: "none" }}
          p="1rem"
          cursor="pointer"
          onClick={toggleMenu}
        >
          <DivLine />
          <DivLine />
          <DivLine />
        </Div>

        {/* Drop-down menu for mobile */}
        {isMenuOpen && (
          <>
            <Div
              d={{ xs: "flex", lg: "none" }}
              flexDir="column"
              align="center"
              pos="absolute"
              top="6rem"
              right="1rem"
              zIndex="100"
              w="94%"
              bg="rgba(6, 49, 89, 0.9)"
              border="1px solid #ddd"
              boxShadow="0 2px 10px rgba(0, 0, 0, 0.1)"
              borderRadius="5px"
            >
              {menuItems.map((menuItem) => (
                <Link
                  key={menuItem}
                  to={`/${menuItem.toLowerCase()}`} // Use to prop for navigation
                  onClick={closeMenu}
                >
                  <Tag
                    p="0.5rem"
                    m="0.5rem"
                    borderBottom="1px solid #FFFFFF"
                    hoverBg="rgba(6, 49, 89, 0.8)"
                    hoverTextColor="#FFFFFF"
                    cursor="pointer" bg="None"
                    textColor="#FFFFFF"
                    w="100%"
                  >
                    {menuItem}
                  </Tag>
                </Link>
              ))}
            </Div>
            {/* <DivLine /> */}
          </>
        )}
      </Div>
    </div>
  );
};

export default Nav;
