import React, { useState, useEffect } from "react";
import { Div, Text, Container } from "atomize";
import "./containers.css";
import { TestimonialCard } from "../Components";
import axios from "axios";

const Testimonial = () => {
  const [clientData, setClientData] = useState([]);
  const [messagesData, setMessagesData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [clientsResponse, messagesResponse] = await Promise.all([
          axios.get("http://localhost:5000/api/clients/list"),
          axios.get("http://localhost:5000/api/messages/list"),
        ]);

        const [clientsData, messagesData] = await Promise.all([
          clientsResponse.data,
          messagesResponse.data,
        ]);

        setClientData(clientsData);
        setMessagesData(messagesData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();

    // Polling every 10 seconds for real-time updates
    const intervalId = setInterval(fetchData, 10000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Container p={{ x: { xs: "1rem", md: "2rem" }, y: "2rem" }} m="auto" w={{ xs: "90%", md: "70%" }}>
      <Div w="100%" d="flex" flexDir="column" textAlign="center">
        <Text textSize="heading" textColor="dark" m={{ b: "1rem" }}>
          FAQ
        </Text>

        {loading ? (
          <p>Loading...</p>
        ) : (
          clientData.slice(0, 3).map((client) => {
            const associatedMessage = messagesData.find(
              (message) => message.client_id === client.client_id
            );

            return (
              <TestimonialCard
                key={client.client_id}
                client_firstname={client.client_firstname}
                client_lastname={client.client_lastname}
                testimonial={associatedMessage ? associatedMessage.message_details : ""}
              />
            );
          })
        )}
      </Div>
    </Container>
  );
};

export default Testimonial;
