import React, { useState } from "react";
import { Container, Row, Col, Input, Button, Icon, Text, Div } from "atomize";
import axios from "axios";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [submitting, setSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setSubmitting(true);

      // Corrected base URL to use "http" instead of "https"
      const response = await axios.post("http://localhost:5000/api/messages/create", formData);

      // Reset form after successful submission
      setFormData({
        name: "",
        email: "",
        message: "",
      });

      // Display success message
      setSuccessMessage("Form submitted successfully!");

      // Clear any previous error messages
      setErrorMessage(null);
    } catch (error) {
      // Handle error and display an error message
      setErrorMessage("Failed to submit the form. Please try again.");

      // Clear any previous success messages
      setSuccessMessage(null);
    } finally {
      setSubmitting(false);
    }
  };

  const ContactInfo = () => {
    return (
      <Row>
        <Col textAlign="center" >
          <Text textSize="heading" textColor="dark" m={{ b: "1rem" }}>
            Contact us
          </Text>
          <Text textSize="body" textColor="medium" m={{ b: "1rem" }}>
            <Icon name="Location" size="18px" color="info800" m={{ r: "0.5rem" }} />
            308 Virgin Island Street, Rocky Crest, Windhoek
          </Text>
          <Text textSize="body" textColor="medium" m={{ b: "1rem" }}>
            <Icon name="Mail" size="18px" color="info800" m={{ r: "0.5rem" }} />
            info@akseu.net
          </Text>
          <Text textSize="body" textColor="medium" m={{ b: "1rem" }}>
            <Icon name="Notification" size="18px" color="info800" m={{ r: "0.5rem" }} />
            +264 81 300 8813
          </Text>
        </Col>
      </Row>
    );
  };

  return (
    <Container p={{ x: { xs: "1rem", md: "2rem" }, y: "2rem" }} m="auto" w={{ xs: "90%", md: "70%" }}>
      <ContactInfo />
      <Row>
        <Col w={{ xs: "100%", md: "70%" }}>
          <form onSubmit={handleSubmit}>
            <Input
              placeholder="Your Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              m={{ b: "1rem" }}
              required
            />
            <Input
              placeholder="Your Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              m={{ b: "1rem" }}
              required
            />
            <Input
              placeholder="Your Message"
              name="message"
              multiline
              rows="5"
              value={formData.message}
              onChange={handleChange}
              m={{ b: "1rem" }}
              required
            />
            <Button
              type="submit"
              bg="rgba(6, 49, 89, 0.8)"
              hoverBg="rgba(6, 49, 89, 1)"
              textColor="#FFFFFF"
              m="auto"
              disabled={submitting}
            >
              {submitting ? "Submitting..." : "Submit"}
            </Button>
          </form>
          <Div m="20px" textAlign="center" textWeight="700">
            {successMessage && (
              <p style={{ color: "green" }}>{successMessage}</p>
            )}
            {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
          </Div>

        </Col>
      </Row>
    </Container>
  );
};

export default Contact;
