import React from "react";
import { Div, Text, Icon } from "atomize";
import InfoData from "../../data/Information";

const Info = (props) => {
  return (
    <Div
      border="1px solid navy"
      rounded="5px"
      bg="white"
      m="1rem"
      h="auto"
      p="1rem"
    >
      <Div
        borderBottom="1px solid #063159"
        borderTop="1px solid #063159"
        p="10px"
        m="10px"
        textAlign="center"
      >
        <Text
          tag="h3"
          textSize="heading"
          textColor="rgba(6, 49, 89, 0.8)"
          textWeight="600"
          p="10px"
        >
          What's new?
        </Text>
      </Div>

      {InfoData.map((info, index) => (
        <Div
          shadow="0 10px 24px -10px black"
          rounded="12px"
          overflow="hidden"
          h="auto"
          d="flex"
          flexDir="column"
          align="center"
          justify="center"
          p={{ t: "20px", b: "20px" }}
        >  <Text
          key={index}
          tag="p"
          textSize="body"
          textColor="dark"
          textWeight="300"
          p="10px"
          textAlign="center"
        >

            <a href={info.link}>
              <b>{info.title}</b>
            </a>
            <p>{info.data} <a href={info.link}>....Open site</a></p>

          </Text>
        </Div>
      ))}
      <Div
      h="auto"
      d="flex"
      flexDir="column"
      align="center"
      justify="center"
      p={{ t: "20px", b: "20px" }}
      >
        <a href="your_advertise_link_here">
          {/* Icon for mouse pointer */}
          <Icon name="EyeSolid" size="18px" color="blue" />
           View more content!
        </a></Div>

    </Div>
  );
};

export default Info;
