import "../App.css";
import { ThemeProvider } from "atomize";
import {
  Header,
} from "../components/Components";

const theme = {
  colors: {
    green: "##6FCAA8",
    darkGreen: "#36AB80",
    black: "#0A1F44",
    dark: "#0A1F44",
    white: "#FFFFF",
    gray: "#B0B7C3",
    darkGray: "#8A94A6",
    blue: "#026DD6",
    darkBlue: "#01408F",
  },
};

function App() {
  return (
    <ThemeProvider theme={theme}>
        <Header />
    </ThemeProvider>
  );
}

export default App;
