const Offer = [
  {
    id: 0,
    title: "Digital Marketing",
    description:
      "Consulting, Advertising And Marketing Agency with Years of Business Experience. Facilitating and delivery of integrated digital marketing services to clients in Namibia.",
  },
  {
    id: 1,
    title: "Software development",
    description:
      "Software development refers to the design, documentation, programming, testing, and ongoing maintenance of a software deliverable.",
  },
  {
    id: 2,
    title: "Research",
    description:
      "the systematic investigation into and study of materials and sources in order to establish facts and reach new conclusions.",
  },
  {
    id: 2,
    title: "Product trading",
    description: "Electronics and Beauty",
  },
];
export default Offer