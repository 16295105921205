import React, { useState, useEffect } from "react";
import { Div, Text, Image, Row, Col } from "atomize";
import "./containers.css";
import nustMtcBanner from "../../assets/Banners/mtc-innov.jpeg";
import pyconBanner from "../../assets/Banners/pycon-nam.jpg";
import Advert from "../components/AdvertCard";
import Info from "../components/InfoCard";

const CustomSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const totalSlides = 2;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % totalSlides);
    }, 5000);

    return () => clearInterval(interval);
  }, [currentSlide, totalSlides]);

  const renderSlide = (imageSrc, altText, titleText, descText) => (
    <Div key={imageSrc} h="65vh" m="20px 0px" position="relative" overflow="hidden" rounded= "md">
      <Image
        h="100%"
        w="100%"
        src={imageSrc}
        alt={altText}
        className="slider-image"
        style={{ objectFit: "cover" }}
        rounded= "md"
      />
      <Div
        pos="absolute"
        bottom="0"
        w="100%"
        textAlign="center"
        p={{ xs: "1rem", md: "2rem" }}
        bg="rgba(6, 49, 89, 0.6)"
        shadow="4"
        rounded= "md"
      >
        <Text
          tag="h1"
          textColor="#FFFFFF"
          textSize={{ xs: "display1", md: "display2" }}
          textWeight="700"
          textAlign="center"
          p={{ xs: "10px", md: "20px" }}
        >
          {titleText}
        </Text>
        <Text
          tag="p"
          textColor="#FFFFFF"
          textSize={{ xs: "subheader", md: "body" }}
          textWeight="600"
          p={{ xs: "10px", md: "20px" }}
        >
          {descText}
        </Text>
      </Div>
    </Div>
  );

  return (
    <Row>
      <Col>
        <Info />
      </Col>
      <Col size={{ xs: 12, lg: 6 }}>
        <Div h="70vh" pos="relative" overflow="hidden">
          <Div pos="relative" w="100%" textAlign="center">
            {currentSlide === 0 && renderSlide(nustMtcBanner, "MTC NUST Innovation Bazaar 2023", "MTC Tech Innovation Bazaar 2023", "Attended the MTC NUST Innovation for Pitching Training. (Parcidio Andre, Manuela Dos Santos, and Theron Kasita)")}
            {currentSlide === 1 && renderSlide(pyconBanner, "Python Conference Namibia 2022", "Python Conference Namibia 2022", "Attended the Python conference. Theron Kasita (Center second row from the top)")}
          </Div>
        </Div>
      </Col>
      <Col>
        <Advert />
      </Col>
    </Row>
  );
};

export default CustomSlider;
