const informationData = [
  {
    id: 0,
    title:"Novelty | Episode 16 | Bridging Communication: App Translates Voice and Text into Sign Language",
    data: "Join us in Episode 16 of Novelty as we engage in a conversation with a talented software developer who has created an innovative app that translates voice and text into sign language, breaking communication barriers.",
    link:"https://www.youtube.com/watch?v=c7YZ-6y5ecM",
  },
  {
    id: 0,
    title:"MTC and Nust host innovation bazaar",
    data: "Digital inclusivity… Theron Kasita, co-founder of the Namibian Sign Language Translator mobile application, yesterday showed off the app at the launch of the MTC Tech Innovation Bazaar.",
    link:"https://neweralive.na/posts/mtc-and-nust-host-innovation-bazaar",
  },
  // {
  //   id: 1,
  //   data: "Janilson",
  // },
  // {
  //   id: 2,
  //   data: "Manuela",
  // },
  // {
  //   id: 3,
  //   data: "Theron",
  // },
];

export default informationData;
