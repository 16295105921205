import React from "react";
import { Div, Text } from "atomize";

const TestimonialCard = (props) => {
  return (
    <Div
      bg="white"
      shadow="2"
      rounded="lg"
      p="20px"
      m="10px"
      border={{ b: "1px solid" }}
      borderColor="gray300"
    >
      <Text
        tag="h3"
        textSize="subheader"
        textColor="dark"
        textWeight="600"
        textAlign="center"
        m={{ b: "10px" }}
      >
        {`${props.client_firstname} ${props.client_lastname}`}
      </Text>

      <Text
        textSize="body"
        textColor="medium"
        textAlign="center"
        fontStyle="italic"
      >
        {`"${props.testimonial}"`}
      </Text>
    </Div>
  );
};

export default TestimonialCard;
