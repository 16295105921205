import React from "react";
import { Div, Row, Col, Image, Text } from "atomize";
import Collaborators from "../../data/Collaborators";
import "../../App.css";

const Collaborator = () => {
  return (
    <Div bg="#F8F8F8" p={{ t: "4rem", b: "4rem" }}>
      <Div textAlign="center">
        <Text
          tag="h1"
          textSize="display2"
          m={{ b: "2rem" }}
          textColor="dark"
          textWeight="600"
          textAlign={{ xs: "center", lg: "center" }}
        >
          Our Collaborators
        </Text>
        <p>
          Explore the wonderful partners and collaborators who contribute to our success.
        </p>
      </Div>

      <Row
        d="flex"
        align="center"
        justify="center"
        p={{ t: "2rem", r: "8rem" , l: "8rem" }}
        className="slider-row"
        flexWrap="wrap"
      >
        {Collaborators.map((collaborator) => (
          <Col key={collaborator.id} m="auto" textAlign="center">
            <Image
              h="6rem"
              w="auto"
              src={collaborator.logo}
              alt={collaborator.name}
              borderRadius="10px"
              shadow="4"
              hoverShadow="5"
              rounded="12px"
            />
          </Col>
        ))}
      </Row>
    </Div>
  );
};

export default Collaborator;
